body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    font-family: Roboto, sans-serif;
    color: #393E42;
    background: #f5f7fd;
}

h1,
h2,
h3 {
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.03em;
}

@font-face {
    font-family: "Segoe UI";
    src: local("Segoe UI"),
        url("./fonts/segoeui.ttf") format("truetype");
}